.drawerOpen {
  width: 240px;
  overflow-x: hidden;
}
.drawerOpenRoot {
  width: 240px;
  transition: width 350ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
}

.drawerClose {
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
}

.drawerPinButton {
  margin-left: 4px;
  transition: color 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawerPinButton:hover {
  background-color: transparent;
  color: #ffffff;
}

.link {
  text-decoration: none;
  color: #e0e0e0;
  transition: color 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.link:hover {
  color: #ffffff;
}

.socialLink {
  display: flex;
  align-items: center;
  color: #e0e0e0;
  transition: color 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.socialLink:hover {
  color: #ffffff;
}

.submitMapButton {
  height: 5px;
  min-width: unset;
  width: 6px;
  margin-left: 0;
  padding: 0;
}

.submitMapButtonBig {
  width: 100%;
}

.icon {
  margin: 100px;
}

.icon svg {
  width: 20px;
  overflow: visible;
}

.mapIcon {
  margin-left: -3px;
  height: 18px;
}

.flag {
  height: 24px;
  border-radius: 3.75px;
}

.flagSmall {
  height: 16px;
}

.scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}
