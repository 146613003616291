@import 'Theme/Drawer/index.css';

html,
body {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

.MapCardThin {
  position: relative;
  width: 282px;
  height: 192px;
  border-radius: 16px;
  padding: 16px;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
}

.MapCardThin__Bg {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(32, 33, 38, 0.2) 0%,
    rgba(32, 33, 38, 0.6446) 44.01%,
    rgba(32, 33, 38, 0.92) 100%
  );
}

.MapCardThin__Bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
}

.MapCardThin__Content {
  position: relative;
  width: 100%;
  margin-top: auto;
  display: flex;
  gap: 8px;
}
.youtube__player {
  height: 100%;
}
