#e6rmbR0xhgZ3_ts {
  animation: e6rmbR0xhgZ3_ts__ts 1500ms linear infinite normal forwards;
}

@keyframes e6rmbR0xhgZ3_ts__ts {
  0% {
    transform: translate(32.28248px, 32px) scale(1, 1);
  }
  20% {
    transform: translate(32.28248px, 32px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  53.333333% {
    transform: translate(32.28248px, 32px) scale(0.80126, 0.801258);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  86.666667% {
    transform: translate(32.28248px, 32px) scale(1, 1);
  }
  100% {
    transform: translate(32.28248px, 32px) scale(1, 1);
  }
}

#e6rmbR0xhgZ3 {
  animation: e6rmbR0xhgZ3_c_o 1500ms linear infinite normal forwards;
}

@keyframes e6rmbR0xhgZ3_c_o {
  0% {
    opacity: 0.18;
  }
  20% {
    opacity: 0.18;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  60% {
    opacity: 0.05;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  93.333333% {
    opacity: 0.18;
  }
  100% {
    opacity: 0.18;
  }
}

#e6rmbR0xhgZ4_ts {
  animation: e6rmbR0xhgZ4_ts__ts 1500ms linear infinite normal forwards;
}

@keyframes e6rmbR0xhgZ4_ts__ts {
  0% {
    transform: translate(32.28248px, 32px) scale(1, 1);
  }
  6.666667% {
    transform: translate(32.28248px, 32px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  40% {
    transform: translate(32.28248px, 32px) scale(0.625338, 0.625338);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  73.333333% {
    transform: translate(32.28248px, 32px) scale(1, 1);
  }
  100% {
    transform: translate(32.28248px, 32px) scale(1, 1);
  }
}

#e6rmbR0xhgZ4 {
  animation: e6rmbR0xhgZ4_c_o 1500ms linear infinite normal forwards;
}

@keyframes e6rmbR0xhgZ4_c_o {
  0% {
    opacity: 0.2;
  }
  6.666667% {
    opacity: 0.2;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  46.666667% {
    opacity: 0.05;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  80% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}

#e6rmbR0xhgZ5_ts {
  animation: e6rmbR0xhgZ5_ts__ts 1500ms linear infinite normal forwards;
}

@keyframes e6rmbR0xhgZ5_ts__ts {
  0% {
    transform: translate(32.2813px, 31.7449px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  33.333333% {
    transform: translate(32.2813px, 31.7449px) scale(0.625338, 0.625338);
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  66.666667% {
    transform: translate(32.2813px, 31.7449px) scale(1, 1);
  }
  100% {
    transform: translate(32.2813px, 31.7449px) scale(1, 1);
  }
}

#e6rmbR0xhgZ5 {
  animation: e6rmbR0xhgZ5_c_o 1500ms linear infinite normal forwards;
}

@keyframes e6rmbR0xhgZ5_c_o {
  0% {
    opacity: 0.3;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  40% {
    opacity: 0.1;
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  73.333333% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.3;
  }
}
